import { StreamType } from '../../enums';
import { useGetTracks } from '../../hooks';
import { useSetLiveView } from '../../hooks/useSetLiveView';
import { SingleTrackViewLayout } from './SingleTrackViewLayout';

interface ISingleTrackView {
  disableSetLive?: boolean;
  streamType: StreamType;
}

export const SingleTrackView = ({
  disableSetLive = false,
  streamType,
}: ISingleTrackView) => {
  const { tracks } = useGetTracks();
  const videoTrack = tracks[streamType];

  useSetLiveView({
    component: 'singleTrackView',
    disableSetLive,
    streamType,
    videoTracks: [videoTrack],
  });

  return (
    <SingleTrackViewLayout streamType={streamType} videoTrack={videoTrack} />
  );
};
