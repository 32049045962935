export const enum StreamType {
  Webcam,
  Slides,
  Screenshare,
}

export enum PipPosition {
  Bottom = 'bottom',
  Top = 'top',
}
