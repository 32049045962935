/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Participant, Track } from 'livekit-client';
import type {
  ParticipantClickEvent,
  TrackReferenceOrPlaceholder,
} from '@livekit/components-core';
import {
  isTrackReference,
  isTrackReferencePinned,
} from '@livekit/components-core';
import {
  ParticipantContext,
  TrackRefContext,
  VideoTrack,
  useEnsureTrackRef,
  useFeatureContext,
  useMaybeLayoutContext,
  useMaybeParticipantContext,
  useMaybeTrackRefContext,
} from '@livekit/components-react';
import { selectConferenceParticipants } from '../../redux/slices/room';
import { ParticipantImage } from '../Form/ParticipantImage';

export function ParticipantContextIfNeeded(
  props: React.PropsWithChildren<{
    participant?: Participant;
  }>,
) {
  const hasContext = !!useMaybeParticipantContext();
  return props.participant && !hasContext ? (
    <ParticipantContext.Provider value={props.participant}>
      {props.children}
    </ParticipantContext.Provider>
  ) : (
    <>{props.children}</>
  );
}

function TrackRefContextIfNeeded(
  props: React.PropsWithChildren<{
    trackRef?: TrackReferenceOrPlaceholder;
  }>,
) {
  const hasContext = !!useMaybeTrackRefContext();
  return props.trackRef && !hasContext ? (
    <TrackRefContext.Provider value={props.trackRef}>
      {props.children}
    </TrackRefContext.Provider>
  ) : (
    <>{props.children}</>
  );
}

const isTrackReferenceValid = (trackReference: TrackReferenceOrPlaceholder) =>
  (trackReference?.source === 'screen_share' ||
    trackReference?.participant.isCameraEnabled) &&
  (trackReference.publication?.kind === 'video' ||
    trackReference.source === Track.Source.Camera ||
    trackReference.source === Track.Source.ScreenShare);

/** @public */
export interface ParticipantTileProps
  extends React.HTMLAttributes<HTMLVideoElement> {
  disableSpeakingIndicator?: boolean;
  onParticipantClick?: (event: ParticipantClickEvent) => void;

  /** The track reference to display. */
  trackRef?: TrackReferenceOrPlaceholder;
}

export const ParticipantTileMinimal = /* @__PURE__ */ React.forwardRef<
  HTMLVideoElement,
  ParticipantTileProps
>(function ParticipantTile({ className, trackRef }: ParticipantTileProps, ref) {
  const trackReference = useEnsureTrackRef(trackRef);
  const streamGoParticipants = useSelector(selectConferenceParticipants);

  const streamGoParticipant = streamGoParticipants.find(
    x => x.ParticipantId === trackReference?.participant.sid,
  );

  const layoutContext = useMaybeLayoutContext();
  const autoManageSubscription = useFeatureContext()?.autoSubscription;

  const handleSubscribe = React.useCallback(
    (subscribed: boolean) => {
      if (
        trackReference.source &&
        !subscribed &&
        layoutContext &&
        layoutContext.pin.dispatch &&
        isTrackReferencePinned(trackReference, layoutContext.pin.state)
      ) {
        layoutContext.pin.dispatch({ msg: 'clear_pin' });
      }
    },
    [trackReference, layoutContext],
  );

  const hasSgImage =
    streamGoParticipant?.ParticipantImgUrl || streamGoParticipant?.StreamGoImg;

  return trackReference.publication || hasSgImage ? (
    <TrackRefContextIfNeeded trackRef={trackReference}>
      <ParticipantContextIfNeeded participant={trackReference.participant}>
        {isTrackReference(trackReference) &&
        isTrackReferenceValid(trackReference) ? (
          <VideoTrack
            className={className}
            manageSubscription={autoManageSubscription}
            onSubscriptionStatusChanged={handleSubscribe}
            ref={ref}
            style={{ background: 'transparent' }}
            trackRef={trackReference}
          />
        ) : (
          <>
            {hasSgImage ? (
              <ParticipantImage
                className={className}
                streamGoParticipant={streamGoParticipant}
              />
            ) : null}
          </>
        )}
      </ParticipantContextIfNeeded>
    </TrackRefContextIfNeeded>
  ) : null;
});
