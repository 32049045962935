import React from 'react';
import { useSelector } from 'react-redux';
import { TrackReferenceOrPlaceholder } from '@livekit/components-core';
import { styled } from '@mui/material/styles';
import { selectLowerThirdsPercentage } from '../../../../redux/slices/room';
import { NameTag } from '../../../DynamicLayout/StreamingView/NameTag';
import { ParticipantTileMinimal } from '../../../LiveKit/ParticipantTileMinimal';
import { StreamType } from '../../enums';

const GridContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100vw',
});

const VideoContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

const Row = styled('div')<{
  lowerThirdsPercentage: number;
}>(({ lowerThirdsPercentage }) => {
  const height = `${lowerThirdsPercentage}vh`;
  return {
    display: 'flex',
    justifyContent: 'center',
    width: lowerThirdsPercentage === 100 ? '100vw' : 'auto',
    height,
  };
});

interface ISingleTrackViewLayout {
  streamType: StreamType;
  videoTrack?: TrackReferenceOrPlaceholder;
}

export const SingleTrackViewLayout = ({
  streamType,
  videoTrack,
}: ISingleTrackViewLayout) => {
  const lowerThirdsPercentage = useSelector(selectLowerThirdsPercentage);

  if (!videoTrack) {
    return null;
  }

  return (
    <GridContainer
      className={streamType === StreamType.Webcam ? 'video-cover' : undefined}
    >
      <Row lowerThirdsPercentage={100 - lowerThirdsPercentage}>
        <VideoContainer key={videoTrack.participant.sid}>
          <ParticipantTileMinimal
            className={
              streamType === StreamType.Webcam ? 'videoCam' : undefined
            }
            trackRef={videoTrack}
          />
          {streamType === StreamType.Webcam ? (
            <NameTag participant={videoTrack.participant} />
          ) : null}
        </VideoContainer>
      </Row>
    </GridContainer>
  );
};
